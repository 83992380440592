import NpsEvaluationComponent from '@components/nps-evaluation/nps-evaluation.component'
import { withTransaction } from '@elastic/apm-rum-react'
import { Input } from '@hub/shared/library/main'
import { AnswerResponse } from '@interfaces/questions/questions.interface'
import React, { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import './nps-question.component.scss'

interface Props extends React.HTMLAttributes<HTMLDivElement> {
  questionTitle: string
  questionType: 'SCORE' | 'TEXT'
  required?: boolean
  position: number
  total: number
  pending?: boolean
  active: boolean | undefined
  sendAnswer: ({ answer, score }: AnswerResponse) => Promise<AnswerResponse>
  setActive: (index: number) => void
  onSubmitAnswer: (event: string) => void
  next: () => void
}

const NpsQuestionComponent = ({
  questionTitle,
  questionType,
  required,
  position,
  total,
  className = '',
  pending = false,
  active = false,
  sendAnswer,
  setActive,
  onSubmitAnswer,
  next,
}: Props) => {
  const [answerQuestion, setAnswerQuestion] = useState<AnswerResponse>({ score: null, answer: null })
  const [questionSend, setQuestionSend] = useState<boolean>(false)
  const [questionActivated, setQuestionActivated] = useState<boolean>(false)
  const [loading, setLoading] = useState<boolean>(false)
  const [timeOuted, setTimeOuted] = useState(true)

  const { t } = useTranslation('npsQuestion')

  const handleOnSubmit = (value: string) => {
    if (onSubmitAnswer && typeof onSubmitAnswer === 'function') {
      if (typeof value === 'number') {
        setAnswerQuestion({ ...answerQuestion, score: value })
        handlePostAnswer(value, 'SCORE')
        onSubmitAnswer(value)
        next()
      } else {
        setAnswerQuestion({ ...answerQuestion, answer: value })
        onSubmitAnswer(value)
      }
    }
  }

  const handlePostAnswer = useCallback(
    (value: string | number, type: 'TEXT' | 'SCORE') => {
      let payload: AnswerResponse

      if (type === 'SCORE') {
        payload = { answer: null, score: Number(value) }
      } else {
        payload = { answer: value.toString(), score: null }
      }

      setLoading(true)
      setQuestionSend(true)
      sendAnswer(payload).finally(() => {
        setLoading(false)
      })
    },
    [sendAnswer]
  )

  const handleTimeout = (timeOuted: boolean) => {
    setTimeOuted(timeOuted)
  }

  useEffect(() => {
    setQuestionActivated(active)
  }, [active, questionSend, setQuestionActivated])

  return (
    <div
      className={`box ${className} ${!questionActivated || questionSend ? 'question-answered' : ''} p-xs-2`}
      style={{ cursor: 'pointer' }}
    >
      <a
        onClick={() => {
          if (!questionSend) {
            setQuestionActivated(true)
            setActive(position)
          }
        }}
        role="presentation"
      >
        <div className="display-flex justify-content-space-between">
          <h5 style={{ margin: '0 0 1rem', maxWidth: '90%' }}>{questionTitle}</h5>
          <div>
            {questionSend ? (
              <i className="fa-sharp fa-solid fa-circle-check green"></i>
            ) : pending ? (
              <i className="fa-sharp fa-solid fa-triangle-exclamation yellow"></i>
            ) : (
              <span>
                <span className="number-position-emphasis">{position + 1}</span>
                <span className="gray">/{total}</span>
              </span>
            )}
          </div>
        </div>
        {questionType === 'SCORE' ? (
          <NpsEvaluationComponent
            selected={answerQuestion?.score}
            loading={loading}
            onClick={handleOnSubmit}
            disabled={false}
          ></NpsEvaluationComponent>
        ) : (
          <Input
            value={answerQuestion?.answer || ''}
            readOnly={loading}
            type="textarea"
            name={`question-answer-${position}`}
            id={`question-answer-${position}`}
            maxLength={255}
            onChange={handleOnSubmit}
            onTimeout={handleTimeout}
            required={required}
          />
        )}
      </a>
    </div>
  )
}
export default withTransaction('NpsQuestion Component', 'component')(NpsQuestionComponent)
