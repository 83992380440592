import logoNegative from '@assets/img/svg/squid-negative.svg'
import logo from '@assets/img/svg/squid.svg'
import { withTransaction } from '@elastic/apm-rum-react'
import MySquid from '@hub/shared/interfaces/my-squid.interface'
import React from 'react'
import './logo.component.scss'

interface Props extends React.HTMLAttributes<HTMLDivElement> {
  forceSquid?: boolean
  forceNegative?: boolean
  forceNormal?: boolean
  width?: string
  height?: string
  styleImg?: any
  configs?: MySquid | null
}

const Logo = ({
  forceSquid,
  className = '',
  forceNegative,
  forceNormal,
  width = 'auto',
  height = '',
  style = { maxWidth: '100px' },
  styleImg,
  id = '',
  configs,
}: Props) => {
  if (configs?._id && !forceSquid) {
    return (
      <figure className={className} id={id} style={style}>
        {configs?.images?.logo?.url && configs?.images?.logo?.url !== 'undefined' ? (
          <img
            width={width}
            height={height}
            src={configs?.images?.logo?.url}
            alt={configs?.name}
            title={configs?.name}
            className={`img-fluid normal ${forceNormal ? 'display-inline-block' : ''}`}
            style={{
              width: width,
              height: height,
              ...styleImg,
            }}
          />
        ) : (
          <h2 className="normal">{configs?.name}</h2>
        )}
        {configs?.images?.negativeLogo?.url && configs?.images?.negativeLogo?.url !== 'undefined' ? (
          <img
            width={width}
            height={height}
            src={configs?.images?.negativeLogo?.url}
            alt={configs?.name}
            title={configs?.name}
            className={`img-fluid negative ${forceNegative ? 'display-inline-block' : ''}`}
            style={{
              width: width,
              height: height,
              ...styleImg,
            }}
          />
        ) : (
          <h2 className="negative">{configs?.name}</h2>
        )}
      </figure>
    )
  }
  return (
    <figure className={className} id={id} style={style}>
      <img
        width={width}
        height={height}
        src={logo}
        alt="Squid"
        title="Squid"
        className={`img-fluid normal ${forceNormal ? 'display-inline-block' : ''}`}
        style={{
          width: width,
          height: height,
          ...styleImg,
        }}
      />
      <img
        width={width}
        height={height}
        src={logoNegative}
        alt="Squid"
        title="Squid"
        className={`img-fluid negative ${forceNegative ? 'display-inline-block' : ''}`}
        style={{
          width: width,
          height: height,
          ...styleImg,
        }}
      />
    </figure>
  )
}
export default withTransaction('Logo Component', 'component')(Logo)
