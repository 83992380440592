import { init as initApm } from '@elastic/apm-rum'
import { Environment } from '@hub/shared'
import '@hub/shared/library/main.css'
import React from 'react'
import { createRoot } from 'react-dom/client'
import { BrowserRouter } from 'react-router-dom'
import ErrorBoundary from './components/error-boundary/error-boundary'
import './i18n.nps'
import './index.scss'
import App from './pages/App.page'
import reportWebVitals from './reportWebVitals'

const url = process.env.REACT_APP_API_URL
interface WindowWithEnvironments extends Window {
  environments?: Environment
}

fetch(url as string)
  .then((r) => r.json())
  .then((r: Environment) => {
    const thisWindow: WindowWithEnvironments = window
    thisWindow['environments'] = r
    return r
  })
  .then((env) => {
    initApm({
      environment: `${env?.env}`,
      serviceName: 'web-influencers-nps-kratos',
      serverUrl: `${env?.apmUrl}`,
    })

    const root = createRoot(document.getElementById('root') as HTMLElement)
    root.render(
      <React.StrictMode>
        <BrowserRouter>
          <ErrorBoundary fallback={<p>Something went wrong.</p>}>
            <App environments={env} />
          </ErrorBoundary>
        </BrowserRouter>
      </React.StrictMode>
    )

    reportWebVitals()
  })
