import i18n from 'i18next'
import Backend from 'i18next-http-backend'
import { initReactI18next } from 'react-i18next'
import enGlobal from './assets/locales/en.json'
import esGlobal from './assets/locales/es.json'
import ptGlobal from './assets/locales/pt.json'
import enAssessmentHeader from './components/assessment-header/locales/en.json'
import esAssessmentHeader from './components/assessment-header/locales/es.json'
import ptAssessmentHeader from './components/assessment-header/locales/pt.json'
import enButtonToCampaigns from './components/button-to-campaigns/locales/en.json'
import esButtonToCampaigns from './components/button-to-campaigns/locales/es.json'
import ptButtonToCampaigns from './components/button-to-campaigns/locales/pt.json'
import enNpsEvaluation from './components/nps-evaluation/locales/en.json'
import esNpsEvaluation from './components/nps-evaluation/locales/es.json'
import ptNpsEvaluation from './components/nps-evaluation/locales/pt.json'
import enNpsQuestion from './components/nps-question/locales/en.json'
import esNpsQuestion from './components/nps-question/locales/es.json'
import ptNpsQuestion from './components/nps-question/locales/pt.json'
import enAnsweredPage from './pages/answered/locales/en.json'
import esAnsweredPage from './pages/answered/locales/es.json'
import ptAnsweredPage from './pages/answered/locales/pt.json'
import enAssessmentPage from './pages/assessment/locales/en.json'
import esAssessmentPage from './pages/assessment/locales/es.json'
import ptAssessmentPage from './pages/assessment/locales/pt.json'
import enExpiredPage from './pages/expired/locales/en.json'
import esExpiredPage from './pages/expired/locales/es.json'
import ptExpiredPage from './pages/expired/locales/pt.json'
import enNotFound from './pages/not-found/locales/en.json'
import esNotFound from './pages/not-found/locales/es.json'
import ptNotFound from './pages/not-found/locales/pt.json'
import enSuccessPage from './pages/success/locales/en.json'
import esSuccessPage from './pages/success/locales/es.json'
import ptSuccessPage from './pages/success/locales/pt.json'

export const defaultNS = 'global'
export const resources = {
  en: {
    global: enGlobal,
    assessmentHeader: enAssessmentHeader,
    answeredPage: enAnsweredPage,
    expiredPage: enExpiredPage,
    successPage: enSuccessPage,
    notFound: enNotFound,
    assessmentPage: enAssessmentPage,
    buttonToCampaigns: enButtonToCampaigns,
    npsQuestion: enNpsQuestion,
    npsEvaluation: enNpsEvaluation,
  },
  pt: {
    global: ptGlobal,
    assessmentHeader: ptAssessmentHeader,
    answeredPage: ptAnsweredPage,
    expiredPage: ptExpiredPage,
    successPage: ptSuccessPage,
    notFound: ptNotFound,
    assessmentPage: ptAssessmentPage,
    buttonToCampaigns: ptButtonToCampaigns,
    npsQuestion: ptNpsQuestion,
    npsEvaluation: ptNpsEvaluation,
  },
  es: {
    global: esGlobal,
    assessmentHeader: esAssessmentHeader,
    answeredPage: esAnsweredPage,
    expiredPage: esExpiredPage,
    successPage: esSuccessPage,
    notFound: esNotFound,
    assessmentPage: esAssessmentPage,
    buttonToCampaigns: esButtonToCampaigns,
    npsQuestion: esNpsQuestion,
    npsEvaluation: esNpsEvaluation,
  },
} as const

i18n
  .use(Backend)
  .use(initReactI18next)
  .init({
    defaultNS,
    ns: [
      'global',
      'assessmentHeader',
      'expiredPage',
      'successPage',
      'answeredPage',
      'notFound',
      'assessmentPage',
      'buttonToCampaigns',
      'npsQuestion',
      'npsEvaluation',
    ],
    load: 'all',
    supportedLngs: ['en', 'es', 'pt'],
    lng: navigator.language?.split('-')[0] || 'pt',
    fallbackLng: 'pt',
    debug: false,
    returnNull: false,
    cache: {
      enabled: false,
    },
    resources,
  })

export default i18n
