import Logo from '@components/logo/logo.component'
import { withTransaction } from '@elastic/apm-rum-react'
import MySquid from '@hub/shared/interfaces/my-squid.interface'
import onConfigChange from '@src/observables/on-config-change.observable'
import React, { useEffect, useRef, useState } from 'react'
import { Link } from 'react-router-dom'
import { Subscription } from 'rxjs'
import './header.component.scoped.scss'

interface Props extends React.HTMLAttributes<HTMLDivElement> {}

const HeaderComponent = ({ className = '' }: Props) => {
  const onConfigChangeSubscription = useRef<Subscription | null>(null)
  const [config, setConfig] = useState<MySquid | null>(null)
  const [isLoading, setIsLoading] = useState(true)

  useEffect(() => {
    onConfigChangeSubscription.current = onConfigChange.subscribe((config) => {
      setConfig(config)
      setIsLoading(false)
    })
    return () => {
      onConfigChangeSubscription?.current?.unsubscribe()
    }
  }, [onConfigChangeSubscription])
  return !isLoading ? (
    <header className={className}>
      <div className="container">
        <Link to={'/'} className="brand-logo" style={{ marginRight: 'auto', marginLeft: '0' }}>
          <Logo configs={config} id={'main-logo'} />
        </Link>
      </div>
    </header>
  ) : null
}
export default withTransaction('Header Component', 'component')(HeaderComponent)
