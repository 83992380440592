import { withTransaction } from '@elastic/apm-rum-react'
import { Button, onEnvironmentChange } from '@hub/shared/library/main'
import React, { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router'
import { Subscription } from 'rxjs'

interface Props extends React.HTMLAttributes<HTMLButtonElement> {}

const ButtonToCampaigns = ({ ...props }: Props) => {
  const onEnvironmentChangeSubscription = useRef<Subscription | null>(null)
  const [platformUrl, setPlatformUrl] = useState<string | undefined>('')

  const { t } = useTranslation('buttonToCampaigns')
  const navigate = useNavigate()

  const toGoCampaigns = () => {
    if (platformUrl) {
      window?.open(`${platformUrl}/campaigns?utm_source=nps&utm_medium=organic&utm_campaign=see-campaigns`, '_self')
    } else {
      navigate('/error')
    }
  }

  useEffect(() => {
    onEnvironmentChangeSubscription.current = onEnvironmentChange?.subscribe((url: any) => {
      setPlatformUrl(url?.productTypeUrl)
    })

    return () => {
      onEnvironmentChangeSubscription?.current?.unsubscribe()
    }
  }, [])

  return (
    <Button onClick={() => toGoCampaigns()} {...props} color="var(--primary_color)" textColor="var(--secondary_color)">
      {t('seeCampaigns')}
    </Button>
  )
}

export default withTransaction('ButtonToCampaigns Component', 'component')(ButtonToCampaigns)
