import React, { ErrorInfo } from 'react'

import { apm } from '@elastic/apm-rum'

class ErrorBoundary extends React.Component {
  state: {
    hasError: boolean
  }
  props: {
    fallback: React.ReactNode
    children: React.ReactNode
  }
  constructor(props: typeof ErrorBoundary.prototype.props) {
    super(props)
    this.state = { hasError: false }
  }

  static getDerivedStateFromError(error: Error) {
    return { hasError: true }
  }

  componentDidCatch(error: Error, info: ErrorInfo) {
    const transaction = apm.startTransaction('Module NPS', 'custom')
    const url = window.location.href
    const httpSpan = transaction?.startSpan('GET ' + url)
    apm.captureError(new Error(`Error Boundary NPS ${error.stack} `))
    httpSpan?.end()
    transaction?.end()
  }

  render() {
    if (this.state.hasError) {
      window.location.href = '/error'
      return this.props.fallback
    }

    return this.props.children
  }
}

export default ErrorBoundary
