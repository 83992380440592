import { withTransaction } from '@elastic/apm-rum-react'
import { Tag } from '@hub/shared/library/main'
import onLangChange from '@src/observables/on-lang-change.observable'
import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Subscription } from 'rxjs'
import './assessment-header.component.scoped.scss'

interface Props extends React.HTMLAttributes<HTMLDivElement> {
  title: string
  subTitle?: string
  socialNetwork?: string
  dateBegin?: string
  dateEnd?: string
}

interface SocialIcon {
  value: string
  name: string
  icon: string
  color: string
}

const AssessmentHeaderComponent = ({ title, subTitle, socialNetwork = '', dateBegin, dateEnd }: Props) => {
  const onLangChangeSubscription = useRef<Subscription | undefined>(undefined)
  const [lang, setLang] = useState<string>('en')
  const [t] = useTranslation('assessmentHeader')
  const [tagSocial, setTagSocial] = useState<SocialIcon | undefined>({
    value: 'instagram',
    name: 'Instagram',
    icon: 'fa-brands fa-instagram',
    color: 'instagram',
  })

  const socialNetworks = useMemo(
    () => [
      {
        value: 'instagram',
        name: 'Instagram',
        icon: 'fa-brands fa-instagram',
        color: 'instagram',
      },
      {
        value: 'youtube',
        name: 'Youtube',
        icon: 'fa-brands fa-youtube',
        color: 'youtube',
      },
      {
        value: 'tiktok',
        name: 'TikTok',
        icon: 'fa-brands fa-tiktok',
        color: 'var(--text_color)',
      },
      {
        value: 'twitter',
        name: 'Twitter',
        icon: 'fa-brands fa-x-twitter',
        color: 'var(--text_color)',
      },
    ],
    []
  )

  const formatDate = useCallback(
    (date: string) => {
      const newDate = new Date(date)
      return newDate?.toLocaleDateString(lang)
    },
    [lang]
  )

  const isBeginDateGreaterThanEndDate = useMemo(() => {
    const beginDate = new Date(dateBegin || '')
    const endDate = new Date(dateEnd || '')
    return beginDate > endDate
  }, [dateBegin, dateEnd])

  useEffect(() => {
    const networkSelect = socialNetworks.find((item) => item.value === socialNetwork)
    setTagSocial(networkSelect)
  }, [socialNetwork, socialNetworks])

  useEffect(() => {
    onLangChangeSubscription.current = onLangChange.subscribe((lang: any) => {
      if (lang) {
        setLang(lang)
      }
    })
    return () => onLangChangeSubscription?.current?.unsubscribe()
  }, [])

  return (
    <section className="box-shadow p-3 container" style={{ backgroundColor: 'var(--background_secondary)', width: '100%' }}>
      <h4 style={{ marginBottom: '0.5rem' }}>{title}</h4>
      <h6>{subTitle}</h6>
      <div className="display-flex" style={{ flexWrap: 'wrap' }}>
        {tagSocial ? (
          <Tag icon={<i className={`${tagSocial?.icon} ${tagSocial.color}`}></i>} labels={tagSocial?.name} separator className={'mr-2'} />
        ) : null}
        {dateBegin ? (
          <Tag
            icon={<i className="fa-regular fa-calendar-day"></i>}
            labels={[t('begin'), formatDate(dateBegin)]}
            separator
            className={'mr-2'}
          />
        ) : null}
        {dateEnd && !isBeginDateGreaterThanEndDate ? (
          <Tag icon={<i className="fa-regular fa-calendar-check"></i>} labels={[t('end'), formatDate(dateEnd)]} separator />
        ) : null}
      </div>
    </section>
  )
}
export default withTransaction('AssessmentHeader Component', 'component')(AssessmentHeaderComponent)
