import AssessmentHeaderComponent from '@components/assessment-header/assessment-header.component'
import { withTransaction } from '@elastic/apm-rum-react'
import { Button, Loader, LogService, NpsService, onEnvironmentChange } from '@hub/shared/library/main'
import { AnswerResponse, Question, QuestionResponse } from '@interfaces/questions/questions.interface'
import NpsQuestionComponent from '@src/components/nps-question/nps-question.component'
import axios from 'axios'
import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router'
import { createSearchParams } from 'react-router-dom'
import { Subscription } from 'rxjs'
import { Research } from '../App.page'
import './assessment.page.scoped.scss'

interface CampaignInfo {
  costumer: string
  startDate: string
  endDate: string
  exhibitionName: string
  id: string
  organizationId: string
  title: string
  socialNetworks?: Array<string>
}

interface ResearchInfo {
  researchId: string
  questionGroup: {
    questions: Question[]
  }
}

interface Props {
  research: Research | null
  platform: string | undefined
}

const AssessmentPage = ({ research, platform }: Props) => {
  const logService = useMemo(() => new LogService(), [])
  logService.init('Assessment Page')

  const { campaignId } = useParams()

  const onEnvironmentChangeSubscription = useRef<Subscription | null>(null)
  const [npsUrl, setNpsUrl] = useState('')
  const [active, setActive] = useState<number | undefined>(0)
  const [width, setWidth] = useState<number>(window.innerWidth)
  const [isLoading, setIsLoading] = useState(false)
  const [researchInfo, setResearchInfo] = useState<ResearchInfo | null>(null)
  const [researchCampaign, setResearchCampaign] = useState<QuestionResponse | null>(null)

  const { t } = useTranslation('assessmentPage')
  const npsService = useMemo(() => new NpsService(npsUrl, axios), [npsUrl])

  const navigate = useNavigate()

  if (!campaignId) {
    navigate('error')
  }

  const allQuestionsAnswered = useMemo(
    () =>
      researchInfo?.questionGroup?.questions
        ?.filter(({ question_type }) => question_type === 'SCORE')
        ?.every(({ answer }) => answer?.value),
    [researchInfo?.questionGroup?.questions]
  )

  const isFielTextRequired = useMemo(
    () =>
      researchInfo?.questionGroup?.questions
        ?.filter(({ question_type }) => question_type === 'SCORE')
        ?.some((question) => (question?.answer?.value ? Number(question?.answer?.value) < 4 : false)),
    [researchInfo?.questionGroup?.questions]
  )

  const handleSubmit = (questionId: string, value: string) => {
    const newQuestions = [...(researchInfo?.questionGroup?.questions as Question[])]
    const questionToUpdate = newQuestions?.find((question) => question?.id === questionId)
    if (questionToUpdate) {
      questionToUpdate.answer = {
        value,
      }
      const index = newQuestions?.findIndex((question) => question?.id === questionId)
      newQuestions[index] = questionToUpdate
      setResearchInfo((oldResearchInfo) => ({ ...oldResearchInfo, questionGroup: { questions: newQuestions } } as ResearchInfo))
    }
  }

  const handleNext = (index: number) => {
    setActive(researchInfo?.questionGroup?.questions?.findIndex((question, i) => !question?.answer?.value && i > index))
  }

  const sendAnswer = useCallback(
    async ({
      questionId,
      answer,
      score,
    }: {
      questionId: string | undefined
      answer: string | null | undefined
      score: number | null | undefined
    }) => {
      if (npsUrl) {
        try {
          if (!answer && !score) {
            return navigate(`/success/${platform?.toLowerCase()}`)
          }

          const response = await npsService.sendAnswer({
            researchId: researchInfo?.researchId,
            questionId,
            token: research?.token,
            answer,
            score,
          })

          if (response?.success) {
            if (!!answer && allQuestionsAnswered) {
              return navigate(`/success/${platform?.toLowerCase()}`)
            }
          } else {
            logService.error({ message: 'Error sending motivation', error: response })
            navigate('/error')
          }
        } catch (error) {
          logService.error({ message: 'Error sending motivation', error })
          navigate('/error')
        }
      }
    },
    [logService, navigate, npsService, allQuestionsAnswered, researchInfo, research, npsUrl, platform]
  )

  useEffect(() => {
    const handleResize = () => setWidth(window.innerWidth)
    window.addEventListener('resize', handleResize)
    return () => window.removeEventListener('resize', handleResize)
  }, [])

  useEffect(() => {
    if (campaignId && research?.type && research?.token && npsUrl) {
      npsService
        .getNPSInfos({ campaignId, token: research?.token })
        .then((data: QuestionResponse) => {
          if (data?.error) {
            logService.error({ message: 'Error on get NPS infos', error: data?.error })
            navigate({
              pathname: '/not-found',
              search: research?.idWebapp
                ? createSearchParams({
                    idWebapp: research?.idWebapp,
                  }).toString()
                : '',
            })
          } else if (data?.done) {
            navigate({
              pathname: `/answered/${platform?.toLowerCase()}`,
              search: research?.idWebapp
                ? createSearchParams({
                    idWebapp: research?.idWebapp,
                  }).toString()
                : '',
            })
          } else {
            setResearchInfo({ researchId: data?.id, questionGroup: data?.questionGroup })
            setResearchCampaign({ ...data })
          }
        })
        .catch((error: Error) => {
          navigate('/error')
          logService.error({ message: 'Error on get NPS infos', error })
        })
        .finally(() => {
          setIsLoading(false)
        })
    }
  }, [campaignId, logService, npsService, npsUrl, research, platform, navigate])

  useEffect(() => {
    onEnvironmentChangeSubscription.current = onEnvironmentChange?.subscribe((url: { [key: string]: string }) => {
      setNpsUrl(url?.apiNpsUrl)
    })

    return () => {
      onEnvironmentChangeSubscription?.current?.unsubscribe()
    }
  }, [])

  if (isLoading) {
    return (
      <div className="text-center py-3" style={{ width: '100%' }}>
        <Loader size="big" color="pink" />
      </div>
    )
  }

  return (
    <div className="assessment-page">
      <AssessmentHeaderComponent
        title={t('satisfactionSurvey')}
        subTitle={researchCampaign?.campaignTitle}
        socialNetwork={researchCampaign?.campaignInfo?.socialNetworks?.[0]}
        dateBegin={researchCampaign?.campaignInfo?.startDate}
        dateEnd={researchCampaign?.campaignInfo?.endDate}
      ></AssessmentHeaderComponent>
      <div className="list-questions container p-0 mt-5">
        {researchInfo?.questionGroup?.questions?.map((question, index) => (
          <NpsQuestionComponent
            key={question?.id}
            questionTitle={question?.question}
            questionType={question?.question_type}
            position={index}
            total={researchInfo?.questionGroup?.questions?.length}
            active={active === index}
            pending={!question?.answer?.value && Number(active) > index}
            onSubmitAnswer={(score: string) => handleSubmit(question?.id, score)}
            setActive={setActive}
            required={isFielTextRequired}
            sendAnswer={({ answer = undefined, score = undefined }: AnswerResponse) =>
              sendAnswer({ questionId: question?.id, answer, score })
            }
            next={() => handleNext(index)}
          />
        ))}
      </div>
      <div className={`container actions ${width > 991 ? 'desktop' : ''} display-flex justify-content-space-between`}>
        <Button color="transparent" borderColor="var(--text_color)" textColor="var(--text_color)" size={`${width > 991 ? 'md' : 'sm'}`}>
          {t('toCancel')}
        </Button>
        <Button
          color="var(--primary_color)"
          textColor="var(--secondary_color)"
          size={`${width > 991 ? 'md' : 'sm'}`}
          onClick={({ answer = null, score = null }: AnswerResponse) => {
            sendAnswer({
              questionId: researchInfo?.questionGroup?.questions?.find((question) => question?.question_type === 'TEXT')?.id,
              answer: researchInfo?.questionGroup?.questions?.find((question) => question?.question_type === 'TEXT')?.answer?.value,
              score,
            })
          }}
          disabled={
            !allQuestionsAnswered ||
            (isFielTextRequired &&
              !researchInfo?.questionGroup?.questions?.find(({ question_type }) => question_type === 'TEXT')?.answer?.value)
          }
        >
          {t('toSave')}
        </Button>
      </div>
    </div>
  )
}
export default withTransaction('Assessment Page', 'page')(AssessmentPage)
