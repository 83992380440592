import notFoundImage from '@assets/img/svg/not-found.svg'
import { withTransaction } from '@elastic/apm-rum-react'
import MySquid from '@hub/shared/interfaces/my-squid.interface'
import { Button } from '@hub/shared/library/main'
import onConfigChange from '@src/observables/on-config-change.observable'
import { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router'
import { Subscription } from 'rxjs'
import './not-found.page.scoped.scss'

const NotFoundPage = () => {
  const navigate = useNavigate()
  const { t } = useTranslation('notFound')
  const onConfigChangeSubscription = useRef<Subscription | null>(null)
  const [config, setConfig] = useState<MySquid | null>(null)

  useEffect(() => {
    onConfigChangeSubscription.current = onConfigChange.subscribe((config) => {
      setConfig(config)
    })
    return () => {
      onConfigChangeSubscription?.current?.unsubscribe()
    }
  }, [onConfigChangeSubscription])

  return (
    <div className="not-found-page">
      <figure className="display-flex justify-content-center">
        {config?._id ? (
          <i className={'fa-regular fa-link-simple-slash'} style={{ fontSize: '150px', color: 'var(--primary_color)' }}></i>
        ) : (
          <img src={notFoundImage} alt="Not Found" />
        )}
      </figure>
      <article>
        <h1 className="text-center m-0">{t('notFound')}</h1>
        <p className="text-center" style={{ maxWidth: '220px' }}>
          {t('notFoundMessage')}
        </p>
      </article>
      <Button onClick={() => navigate('/')}>{t('backToHome')}</Button>
    </div>
  )
}

export default withTransaction('Not Found Page', 'page')(NotFoundPage)
