import { withTransaction } from '@elastic/apm-rum-react'
import MySquid from '@hub/shared/interfaces/my-squid.interface'
import { ElektraService, Loader, onEnvironmentChange } from '@hub/shared/library/main'
import HeaderComponent from '@src/components/header/header.component'
import i18n from '@src/i18n.nps'
import ErrorLayout from '@src/layouts/error/error.layout'
import onConfigChange from '@src/observables/on-config-change.observable'
import onLangChange from '@src/observables/on-lang-change.observable'
import axios from 'axios'
import jwt_decode from 'jwt-decode'
import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { Navigate, Route, Routes, useNavigate } from 'react-router-dom'
import { Subscription } from 'rxjs'
import './App.page.scoped.scss'
import './App.page.scss'
import AnsweredPage from './answered/answered.page'
import Assessment from './assessment/assessment.page'
import ExpiredPage from './expired/expired.page'
import SuccessPage from './success/success.page'

export interface Research {
  lang: string | null
  type: string | null
  token: string | null
  idWebapp: string | null
}

interface Token {
  exp: number
}

interface Props {
  environments: {
    squidApis: {
      elektra: string
      daredevil: string
      nps: string
    }
    squidProducts: {
      [key: string]: string
    }
  }
}

const App = (props: Props) => {
  const [research, setResearch] = useState<Research | null>(null)
  const [type, setType] = useState<string | null | undefined>(null)
  const [elektraUrl, setElektraUrl] = useState<string | undefined>('')
  const onConfigChangeSubscription = useRef<Subscription | null>(null)
  const apmIsInit = useRef<boolean>(false)
  const [config, setConfig] = useState<MySquid | null>(null)
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const navigate = useNavigate()
  const elektraService = useMemo(() => new ElektraService(elektraUrl, axios), [elektraUrl])

  const handleSetLanguage = useCallback(() => {
    try {
      const storageLang = window.localStorage.getItem('language')
      const langParams = research?.lang

      const languageNavigator = () => {
        const lang = navigator?.language?.split('-')[0]
        if (['en', 'pt', 'es'].includes(lang)) {
          return lang
        }
        return null
      }

      const currentLang = langParams ?? storageLang ?? i18n.language ?? languageNavigator() ?? 'pt'

      i18n.changeLanguage(currentLang)
      onLangChange.next(currentLang as 'pt' | 'en' | 'es')
    } catch (err: any) {
      console.error({ message: err.message, error: err })
    }
  }, [research?.lang])

  const setColors = useCallback(() => {
    let variables
    if (config?._id) {
      variables = `
      :root {
        --primary_color: ${config?.theme?.primaryColor};
        --secondary_color: ${config?.theme?.secondaryColor};
        --tertiary_color: ${config?.theme?.textColor};
      }
      `
      if (config?.theme?.theme && config?.theme?.theme !== 'both') {
        document.body.classList.add(config.theme.theme)
      } else if (window?.matchMedia && window?.matchMedia('(prefers-color-scheme: dark)')?.matches) {
        document.body.classList.add('dark')
      }
    }
    const head = document.getElementsByTagName('head')[0]
    const style = document.createElement('style')
    if (variables) {
      style.appendChild(document.createTextNode(variables))
    }
    head.appendChild(style)
  }, [config])

  const getConfigs = useCallback(async () => {
    try {
      if (research?.idWebapp && research?.idWebapp !== 'undefined' && elektraUrl) {
        const response = await elektraService.getConfigById(research?.idWebapp)
        if (response?._id) {
          onConfigChange.next(response)
        }
        return response
      }
      setIsLoading(false)
    } catch (err: any) {
      console.error({ message: err?.message, error: err })
      setIsLoading(false)
    }
  }, [elektraService, elektraUrl, research])

  useEffect(() => {
    handleSetLanguage()
    setColors()
  }, [handleSetLanguage, config, setColors])

  useEffect(() => {
    const query = new URLSearchParams(window.location.search)
    const lang = query?.get('lang') || 'pt'
    const type = query?.get('type')
    const token = query?.get('token')
    const idWebapp = query?.get('idWebapp')
    setResearch({ lang, type, token, idWebapp: idWebapp === 'null' ? null : idWebapp })
    setType(research?.type)

    if (token) {
      try {
        const decodedToken: Token = jwt_decode(token)
        const expirationDate = new Date(decodedToken.exp * 1000)

        const isTokenExpired = expirationDate <= new Date()

        if (isTokenExpired) {
          return navigate(`/expired/${type}`)
        }
      } catch (err: any) {
        console.error({ message: err.message, error: err })
      }
    }

    type ObjectKey = keyof typeof props.environments.squidProducts
    const plataformType = type?.toLowerCase() as ObjectKey
    onEnvironmentChange?.next({
      apiNpsUrl: props?.environments?.squidApis?.nps,
      elektraUrl: props?.environments?.squidApis?.elektra,
      daredevil: props?.environments?.squidApis?.daredevil,
      productTypeUrl: `${props?.environments?.squidProducts?.[plataformType]}`,
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [research?.type])

  useEffect(() => {
    setElektraUrl(props?.environments?.squidApis.elektra)
    getConfigs()

    onConfigChangeSubscription.current = onConfigChange.subscribe((config) => {
      setConfig(config)
    })
    return () => {
      onConfigChangeSubscription?.current?.unsubscribe()
    }
  }, [getConfigs, props?.environments?.squidApis.elektra])

  if ((research?.idWebapp && research?.idWebapp !== 'undefined' && !config?._id) || isLoading) {
    return (
      <div className="display-flex justify-content-center align-items-center p-3">
        <Loader size="big" />
      </div>
    )
  }

  return (
    <div className="app">
      <HeaderComponent />
      <main className="content">
        <Routes>
          <Route path="/" index element={<Navigate to="/error" />} />
          <Route path="/research/:campaignId" element={<Assessment research={research} platform={type?.toLowerCase()} />} />
          <Route path="/success/:platform" element={<SuccessPage />} />
          <Route path="/answered/:platform" element={<AnsweredPage />} />
          <Route path="/expired/:platform" element={<ExpiredPage />} />
        </Routes>
      </main>
      <Routes>
        <Route path="/error/*" element={<ErrorLayout />} />
      </Routes>
    </div>
  )
}

export default withTransaction('App', 'component')(App)
