import answeredImage from '@assets/img/svg/answered.svg'
import { withTransaction } from '@elastic/apm-rum-react'
import ButtonToCampaigns from '@src/components/button-to-campaigns/button-to-campaigns.component'
import onConfigChange from '@src/observables/on-config-change.observable'
import { useEffect, useLayoutEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { MySquid } from '@hub/shared'
import { Subscription } from 'rxjs'
import './answered.page.scoped.scss'

const AnsweredPage = () => {
  const [width, setWidth] = useState(window.innerWidth)
  const [height, setHeight] = useState(window.innerHeight)
  const { t } = useTranslation('answeredPage')
  const onConfigChangeSubscription = useRef<Subscription | null>(null)
  const [config, setConfig] = useState<MySquid | null>(null)

  useLayoutEffect(() => {
    const updateSize = () => {
      setWidth(window.innerWidth)
      setHeight(window.innerHeight)
    }
    window.addEventListener('resize', updateSize)
    updateSize()
    return () => window.removeEventListener('resize', updateSize)
  }, [])

  useEffect(() => {
    onConfigChangeSubscription.current = onConfigChange.subscribe((config) => {
      setConfig(config)
    })
    return () => {
      onConfigChangeSubscription?.current?.unsubscribe()
    }
  }, [onConfigChangeSubscription])

  return (
    <main
      className="answered-page box-shadow container display-flex justify-content-center align-items-center"
      style={{ height: width < 991 ? `calc(${height}px - 52px)` : 'auto' }}
    >
      <h1 className="title-page text-center" dangerouslySetInnerHTML={{ __html: t('answeredTitle') as string }} />
      <figure className="illustration">
        {config && config?._id ? (
          <i className={'fa-regular fa-badge-check'} style={{ fontSize: '150px', color: 'var(--primary_color)' }}></i>
        ) : (
          <img src={answeredImage} alt={`${t('answeredTitle')}`} />
        )}
      </figure>
      <ButtonToCampaigns className="button" color="pink" />
    </main>
  )
}

export default withTransaction('Answered Page', 'page')(AnsweredPage)
