import simbolSvg from '@assets/img/svg/wave.svg'
import NotFoundPage from '@src/pages/not-found/not-found.page'
import { Navigate, Route, Routes } from 'react-router-dom'
import './error.layout.scoped.scss'
import './error.layout.scss'

export default (props: any) => {
  return (
    <div className="error-layout">
      <span className="bg" style={{ backgroundImage: `url(${simbolSvg})` }} />
      <main className="container box">
        <Routes>
          <Route path="/" index element={<Navigate to="/error/not-found" />} />
          <Route path={'/not-found'} element={<NotFoundPage {...props} />} />
          <Route path="*" element={<Navigate to="/error/not-found" />} />
        </Routes>
      </main>
    </div>
  )
}
