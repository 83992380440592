import { withTransaction } from '@elastic/apm-rum-react'
import { Button } from '@hub/shared/library/main'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import './nps-evaluation.component.scss'

interface Props extends React.HTMLAttributes<HTMLDivElement> {
  selected?: number | null
  loading: boolean
  disabled: boolean
  onClick: (event: any) => any
}

const NPS_VALUES = [
  { value: 1, content: <i className="fa-regular fa-face-pouting"></i> },
  { value: 2, content: <i className="fa-regular fa-face-frown-slight"></i> },
  { value: 3, content: <i className="fa-regular fa-face-meh"></i> },
  { value: 4, content: <i className="fa-regular fa-face-smile"></i> },
  { value: 5, content: <i className="fa-regular fa-face-grin-hearts"></i> },
]

const NpsEvaluationComponent = ({ selected = null, loading = false, disabled = false, className = '', onClick }: Props) => {
  const [selectedItem, setSelectedItem] = useState<number>()
  const { t } = useTranslation('npsEvaluation')

  const handleOnClick = (value: number) => {
    if (onClick && typeof onClick === 'function') {
      onClick(value)
      setSelectedItem(value)
    }
  }

  const handleClassButton = (item: number) => {
    let buttonClasses = ''
    if (selectedItem === item) {
      buttonClasses += 'active'
    }
    if (item <= 2) {
      buttonClasses += ' button-detractors'
    } else if (item === 3) {
      buttonClasses += ' button-neutral'
    } else {
      buttonClasses += ' button-promoter'
    }
    return buttonClasses
  }

  useEffect(() => {
    setSelectedItem(selected || 0)
  }, [selected])
  return (
    <div className={`grid-items py-1 ${className}`}>
      {NPS_VALUES.map((item) => (
        <div className="nps-items" key={item.toString()}>
          <Button
            color="var(--white)"
            textColor="var(--black_light)"
            borderColor="transparent"
            onClick={(e: any) => {
              e.stopPropagation()
              handleOnClick(item?.value)
            }}
            className={`button-nps ${handleClassButton(item?.value)}`}
            disabled={loading || disabled}
          >
            {item?.content}
            {innerWidth > 350 ? <span>{t(`scores.${item?.value}`)}</span> : null}
          </Button>
        </div>
      ))}
    </div>
  )
}
export default withTransaction('NpsEvaluation Component', 'component')(NpsEvaluationComponent)
